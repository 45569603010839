/** * 合作案例 */
<template>
  <v-app class="section-box">
    <v-banner
        single-line
        height="300"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerWmyCase400.jpg"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-banner>  
    <v-tabs centered color="#0084ff" class="g-tabs">
      <v-tab 
        v-for="(v, k) in data" 
        :key="k"
        @click="clickLevelOne(k)"
        >
        <b>{{ v.title }}</b>
      </v-tab>
    </v-tabs>
  <!-- style="width:1200px;margin:0 auto;" -->
  <div style="background-color:#ffffff">
    <v-tabs
      centered
      fixed-tabs
      hide-slider
      class="new-tabs-two"
      v-model="tab"

    >
      <v-tab 
        :value="levelTwoIndex"
        v-for="(v,k) in data[levelOneIndex].tab"
        :key="k"
        @click="clickLevelTwo(k)"
      >{{ v.tabTilte }}</v-tab>
    </v-tabs>
  </div>


  <div class="div-tabs">

    <v-tabs-items v-model="tab" class="new-tabs-items-two">
      <v-tab-item
        v-model="tab"
        v-for="(v, k) in data[levelOneIndex].tab[levelTwoIndex].tabItem"
        :key="k"
      >
        <v-container fluid>
            <v-card
              class="mx-auto item-card"
              max-width="240"
              max-height="410"
              @click="goCaseDetail(v)"
            >
              <v-img
                max-height="340"
                class="tran-sec"
                :src="v.coverImg"
                :aspect-ratio="9/16"
              >
              </v-img>
              <div class="text-box">
                <v-card-text class="title" v-text="v.itemTitle"></v-card-text>
              </div>
            </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>

  </v-app>
</template>

<script>

import textData from '@/static/textData.js'
import qs from 'qs'

export default {
  name: 'Case',
  data() {
    return {
      data: textData.wmyCase,
      levelOneIndex: 0,
      levelTwoIndex: 0,
      tab: null,
      imgLoad: false
    }
  },
  mounted() {
    console.log(this.data)
  },
  methods: {
    // goCaseDetail(levelOneIndex, levelTwoIndex, vid) {
    //   let query = {
    //     source: 'case',
    //     levelOneIndex: levelOneIndex == 2 ? 6 : levelOneIndex,
    //     levelTwoIndex: levelTwoIndex,
    //     vid: vid,
    //   }
    //   let url = '/CaseDetail?'+qs.stringify(query)
    //   window.open(url, '_blank')
    // },
    goCaseDetail(item){
      if(item.detail.type == 3) {
        window.open(item.detail.openUrl, '_blank')
      } else {
        let query = {
          source: 'qita',
          anli: item.self
        }
        let url = '/CaseDetail?'+qs.stringify(query)
        window.open(url, '_blank')
      }
    },
    clickLevelOne(k) {
      this.levelOneIndex = k
      this.levelTwoIndex = 0
    },
    clickLevelTwo(k) {
      this.levelTwoIndex = k
    }
  }

}
</script>

<style lang="scss" scoped>
.section-box {
  background: #f5f8fd;
  position: relative;
  z-index: 6;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-text {
    text-align: center;
    margin-top: 20px;
  }
}

.g-tabs {
  height: 65px;
  background: #fff;
  padding-top: 10px; 
  flex: unset;
  ::v-deep .v-tab {
    font-size: 22px;
    padding: 0 25px;
    &::before {
      background: unset !important;
    }
  }
  ::v-deep.v-ripple__container {
    display: none !important;
  }
}
.new-tabs-two {
  flex: unset;
}


.div-tabs {
  min-height: 520px !important;
  position: relative;
  z-index: 7;
}
</style>